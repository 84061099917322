import React from 'react';
import PropTypes from 'prop-types';

import RenderTree from '@mshops-components-library/render-tree';

import DisabledWrapper from '../../components/disabled-wrapper';

import { useRenderContext } from '../../pages/home/context';

const RenderTreeContainer = ({ componentsList, ...props }) => {
  const { appearance, device } = useRenderContext();
  return (<RenderTree
    {...props}
    deviceType={device}
    node={appearance.getTree()}
    componentsList={componentsList}
    DisabledWrapper={DisabledWrapper}
    globalConfig={appearance?.appearance?.properties?.style_config}
  />);
};

RenderTreeContainer.propTypes = {
  componentsList: PropTypes.shape({}).isRequired,
};

export default RenderTreeContainer;
